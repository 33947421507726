import React, { useState, useEffect } from 'react'
import { getAgenda } from '../../../crud/dedicatedEvent-crud';
import { errorToaster } from "../../../helpers/utils";
import moment from 'moment';
import DateDropdown from '../../../components/CustomDropdown/DateDropdown';
import Loading from '../../../components/Loading';
import AgendaDatesTab from '../GeneralComponents/Agenda/AgendaDatesTab';
import Agenda from '../GeneralComponents/Agenda/Agenda';

const customStyles = {
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        background: isFocused ? "white" : isSelected ? "white" : undefined,
        color: isFocused ? "#FB5850" : isSelected ? "#FB5850" : undefined,
        zIndex: 1,
        cursor: 'pointer'
    }),

    dropdownIndicator: base => ({
        ...base,
        color: "#FFFFFF",
        "&:hover": {
            color: "#572148"
        }
    }),
    control: (base, state) => ({
        ...base,
        background: "#FB5850",
        color: '#FFFFFF',
        border: state.isFocused ? "1px solid #572148" : "1px solid #cdd4db",
        boxShadow: "none",
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '120%'
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, left: '20%', color: '#FFFFFF' }),
};

const EventAgenda = ({ eventData }) => {
    const [loading, setLoading] = useState(false)
    const [agenda, setAgenda] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [agendaDates, setAgendaDates] = useState([])
    const [selectedTrackId, setSelectedTrackId] = useState('')

    const today = moment(eventData.start_date).format("YYYY-MM-DD")
    const [dateValue, setDateValue] = useState(today);

    const getAgendaFunction = async (date) => {
        setLoading(true)
        try {
            const res = await getAgenda(eventData.event_id, date);
            setAgenda(res.data);
            setSelectedTrackId(res.data[0].track_id)
            setLoading(false)
        } catch (error) {
            setAgenda(null)
            setErrorMessage(error.response.data.error)
            setLoading(false)
            // errorToaster(error.response.data.error);
        }
    }

    useEffect(() => {
        getAgendaFunction(dateValue)
        getAllAgendaDates()
    }, [dateValue])


    const getAllAgendaDates = () => {
        const dates = [];
        const currentDate = moment(eventData.start_date);
        const finalDate = moment(eventData.end_date);

        while (currentDate.isSameOrBefore(finalDate, 'day')) {
            dates.push(currentDate.format('YYYY-MM-DD'));
            currentDate.add(1, 'day');
        }

        setAgendaDates(dates)
    }


    return (
        <div id="agenda">
            <div className='d-flex justify-content-center align-items-center'>
                <span className="section-title">Agenda</span>
            </div>
            <div className='date-change-div mt-5'>
                {agendaDates.length > 1 && dateValue && 
                    <AgendaDatesTab 
                    agendaDates={agendaDates}
                    selectedTrackDate={dateValue}
                    setSelectedTrackDate={setDateValue}
                    selectedDateBackground={"#FB5850"}
                    otherTextColor={"white"}
                    background={"rgba(87, 33, 72, 0.65)"}
                    />

                }
            </div>
            {loading ? <div className='loading d-flex justify-content-center align-items-center'>
                <Loading />
            </div>
                : agenda && agenda.length > 0 ? <Agenda
                    agenda={agenda}
                    eventData={eventData}
                    setDateValue={setDateValue}
                    dateValue={dateValue}
                    professionalTemplate={false}
                    selectedTrackId={selectedTrackId}
                    setSelectedTrackId={setSelectedTrackId}
                    selectedBackground={"#FB5850"}
                    otherTextColor={"white"}
                    background={"rgba(87, 33, 72, 0.65)"}
                />
                    :
                    <div className="no-agenda-text text-center">{errorMessage}</div>
            }
            <style jsx global>{`
            #agenda{
                background: #37152E;
            }
            .section-title{
                font-family: 'Caveat Brush';
                font-weight: 400;
                font-size: 72px;
                line-height: 120%;
                color: #FB5850;
                margin-top: 60px;
            }
            .date-change-div{
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 20px 20px;
            }
            .select{
                width: 13%;
                margin-right: 20px;
            }
            .loading{
                margin-top: 60px;
                padding-bottom: 50px;
            }
            .no-agenda-text{
                font-family: 'Caveat Brush';
                font-weight: 400;
                font-size: 30px;
                line-height: 120%;
                color: #fff;
                margin-top: 60px;
                padding-bottom: 60px;
            }
        `}</style>
        </div>
    )
}

export default EventAgenda
