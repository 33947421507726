import React from 'react'

const SoldOut = () => {
    return (
        <>
            <div className="sold-out-btn d-flex align-items-center justify-content-center py-2 px-4">
                <span className="">Sold Out</span>
            </div>
            <style jsx>{`
                .sold-out-btn{
                    background: rgba(204, 204, 204, 1);
                    box-shadow: 0px 2.22081px 8.32803px rgba(0, 0, 0, 0.15);
                    border-radius: 5px;
                    // border-radius: 20px 20px 50px 40px;
                    // clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 100%);
                }
                .sold-out-btn span{
                    font-family: Prompt;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 130%;
                    text-align: center;
                    color: #666666;
                }
                @media only screen and (max-width: 786px){
                .sold-out-btn{
                    height: 40px;
                }
                .sold-out-btn span{
                    font-size: 14px;
                    font-weight: 600;
                }
            }
                `}
            </style>
        </>
    )
}

export default SoldOut
