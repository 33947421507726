import React from "react";
import speaker from "../../../../public/img/event_speaker_section/speaker.svg";
import LinkedIn from "../../../../public/img/event_speaker_section/LinkedIn.svg";
import facebook from "../../../../public/img/event_speaker_section/facebook.svg";
import twitter from "../../../../public/img/event_speaker_section/twitter-logo-white.svg";
import website from "../../../../public/img/event_speaker_section/website.svg";
import { useEffect } from "react";
import { getSpeakers } from "../../../crud/dedicatedEvent-crud";
import { useState } from "react";
import ReadMore from "../../../components/ReadMoreText";
import { sampleSpeakersData } from "../../../data/sampleEvent";
import { errorToaster } from "../../../helpers/utils";
import TrendySideViewModal from "../GeneralComponents/TrendySideViewModal";

const EventSpeakers = ({ eventUrl, eventData, samplePage }) => {
  const [speakerData, setSpeakerData] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const [speakers, setSpeakers] = useState([]);

  const getMargins = (length) => {
    if (length === 4) {
      return [180, 110, 60, 120];
    }
    if (length === 3) {
      return [150, 90, 50];
    }
    if (length === 2 || length === 1) {
      return [100, 45];
    }
  };

  const getMobileMargins = (index) => {
    let mar = ["", 70, 15];
    if (index < 3) {
      return mar[index];
    }
    if (index > 2 && index % 2 === 0) {
      return 50;
    }
    if (index > 2 && index % 2 !== 0) {
      return 100;
    }
  };

  const getAllSpeakers = async () => {
    try {
      const res = await getSpeakers(eventUrl);
      let speakerData = res.data.sort((a, b) => {
        return a.speaker_order - b.speaker_order;
      });
      setSpeakers(speakerData);
    } catch (error) {
      errorToaster(
        "Something went wrong while trying to get Speaker, please try again later"
      );
    }
  };

  useEffect(() => {
    if (samplePage) {
      setSpeakers(sampleSpeakersData);
    } else {
      getAllSpeakers();
    }
  }, [eventUrl]);
  const SocialMediaLinks = ({ each }) => {
    const handleLinkClick = (e) => {
      // Prevent the propagation of the click event
      e.stopPropagation();
    };

    return (
      <div className="mt-1 d-flex align-items-start" style={{ marginLeft: 5 }}>
        {each?.facebook_url && (
          <div className="facebook_wrapper" style={{ marginRight: 20 }}>
            <a href={each.facebook_url} target="_blank" onClick={handleLinkClick}>
              <img className="facebook" src={facebook} alt="facebook" />
            </a>
          </div>
        )}
        {each?.twitter_url && (
          <div className="twitter_wrapper" style={{ marginRight: 20 }}>
            <a href={each.twitter_url} target="_blank" onClick={handleLinkClick}>
              <img className="twitter" src={twitter} alt="twitter" />
            </a>
          </div>
        )}
        {each?.linkedin_url && (
          <div style={{ marginRight: 20 }}>
            <a href={each.linkedin_url} target="_blank" onClick={handleLinkClick}>
              <img className="linkedIn" src={LinkedIn} alt="Linked In" />
            </a>
          </div>
        )}
        {each?.website_url && (
          <div className="twitter_wrapper">
            <a href={each.website_url} target="_blank" onClick={handleLinkClick}>
              <img className="website" src={website} alt="website" />
            </a>
          </div>
        )}
      </div>
    );
  };

  const ImageContainer = ({ each, index, length }) => {
    return (
      <>
        <div
          className="people"
          style={{
            backgroundImage:
              each?.image_url && each?.image_url !== ""
                ? `url("${each?.image_url}")`
                : `url()`,
            position: "relative",
            //commented out this part for single line speakers = getMargins(length)[index]
            marginTop: 180,
          }}
          onClick={() => {
            setOpenModal((prev) => !prev);
            setSpeakerData(each);
          }}
        >
          <div className="gray"></div>
          <div className="shadow-card position-absolute end-0 bottom-0 py-2"></div>
          <div className="bio_box position-relative end-0 bottom-0 py-2">
            <div className="speaker-name">{each?.name ? each?.name : ""}</div>
            <div className="speaker-designation">
              {each?.designation ? each?.designation : ""}
            </div>
            <SocialMediaLinks each={each} />
            <div className="p-2" />
          </div>
        </div>
        <TrendySideViewModal
          isOpen={openModal}
          setIsOpen={setOpenModal}
          background="#F5F5F5"
        >
          <div className="p-4 card-box2">
            <div className="text-end">
              <button
                onClick={() => setOpenModal(false)}
                className="border-0"
                style={{
                  background: "#F5F5F5",
                  color: "#525252",
                }}
              >
                <i
                  className="fa-solid fa-xmark"
                  style={{ fontSize: "18px" }}
                ></i>
              </button>
            </div>
            <div className="d-flex mb-4 speaker-box">
              <div className="organiser-image-cont-2 rounded-circle me-3">
                <img
                  src={speakerData?.image_url}
                  // width={0}
                  // height={0}
                  // sizes="100vw"
                  style={{ width: "180px" }}
                  alt={`${speakerData.name} poster image`}
                  className="speaker-image2"
                />
              </div>
              <div className="d-flex flex-column speaker-about ">
                <p className="speaker-name2">{speakerData.name}</p>
                <p className="speaker-designation2">
                  {speakerData.designation}
                </p>
                <p className="speaker-organisation2">
                  {speakerData.organisation}
                </p>
                {speakerData.location && (
                  <p className="speaker-organisation2 gap-2 d-flex">
                    <img src="/img/prolocation.svg" alt="" />
                    {speakerData.location}</p>
                )}
                <div>
                  {speakerData.facebook_url && (
                    <a
                      className="me-2"
                      href={speakerData.facebook_url}
                      target={"_blank"}
                    >
                      <img src="/img/facebook03.svg" alt="" />
                    </a>
                  )}
                  {speakerData.twitter_url && (
                    <a
                      className="me-2"
                      href={speakerData.twitter_url}
                      target={"_blank"}
                    >
                      <img src="/img/twitter-red.svg" alt="" />
                    </a>
                  )}
                  {speakerData.linkedin_url && (
                    <a
                      className="me-2"
                      href={speakerData.linkedin_url}
                      target={"_blank"}
                    >
                      <img src="/img/linkedin03.svg" alt="" />
                    </a>
                  )}
                  {speakerData.website_url && (
                    <a
                      className="me-2"
                      href={speakerData.website_url}
                      target={"_blank"}
                    >
                      <img src="/img/Website03.svg" alt="" />
                    </a>
                  )}
                </div>
              </div>
            </div>

            {speakerData.about &&
              <p
                className="mb-0 desc"
                dangerouslySetInnerHTML={{ __html: `${speakerData.about?.replace(/(?:\r\n|\r|\n)/g, '') || ''}` }}
              />
            }
            {speakerData.tags && speakerData.tags.length > 0 && (
              <div className="speaker-tags">
                <p className="my-3 tag-header">Tags</p>
                <div className="d-flex flex-wrap my-3">
                  {speakerData.tags.map((tag) => (
                    <div key={tag.id} className="me-2 mb-2 tag-container">
                      <span className="tag-name">#{tag.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </TrendySideViewModal>
      </>
    );
  };

  const MobileImageContainer = ({ data, index, length, pos }) => {
    return (
      <>
        <div
          className={`
                ${pos < 1 ? "wrapper" : "wrapper_mobile"} ${length === 1 ? "col-12" : "col-6"
            }
                    ${length === 1 ? "col-lg-12" : "col-lg-6"}`}
          style={{
            display: "flex",
            justifyContent: "center",
          }}

        >
          <div
            className="people mt-5"
            style={{
              backgroundImage:
                data?.image_url && data?.image_url !== ""
                  ? `url("${data?.image_url}")`
                  : `url()`,

            }}


          >
            <div className="gray"></div>
            <div className="shadow-card position-absolute end-0 bottom-0 py-2"></div>
            <div className="bio_box position-relative end-0 bottom-0 py-2">
              <div onClick={() => {
                setOpenModal((prev) => !prev);
                setSpeakerData(data);
              }}>

                <div className="speaker-name">{data?.name ? data?.name : ""}</div>
                <div className="speaker-designation">
                  {data?.designation ? data?.designation : ""}
                </div>
              </div>
              <div
                className="mt-1 d-flex align-items-start"
                style={{ marginLeft: 0 }}
              >
                {data?.facebook_url && (
                  <div className="facebook_wrapper" style={{ marginRight: 20 }}>
                    <a href={data.facebook_url} target={"_blank"}>
                      <img className="facebook" src={facebook} alt={"facebook"} />
                    </a>
                  </div>
                )}
                {data?.twitter_url && (
                  <div className="twitter_wrapper" style={{ marginRight: 20 }}>
                    <a href={data.twitter_url} target={"_blank"}>
                      <img className="twitter" src={twitter} alt={"twitter"} />
                    </a>
                  </div>
                )}
                {data?.linkedin_url && (
                  <div style={{ marginRight: 20 }}>
                    <a href={data.linkedin_url} target={"_blank"}>
                      <img
                        className="linkedIn"
                        src={LinkedIn}
                        alt={"Linked In"}
                      />
                    </a>
                  </div>
                )}
                {data?.website_url && (
                  <div className="twitter_wrapper">
                    <a href={data.website_url} target={"_blank"}>
                      <img className="website" src={website} alt={"website"} />
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <TrendySideViewModal
          isOpen={openModal}
          setIsOpen={setOpenModal}
          background="#F5F5F5"
        >
          <div className="p-sm-4 p-2 py-4 card-box">
            <div className="text-end">
              <button
                onClick={() => setOpenModal(false)}
                className="border-0"
                style={{
                  background: "#F5F5F5",
                  color: "#525252",
                }}
              >
                <i
                  className="fa-solid fa-xmark"
                  style={{ fontSize: "18px" }}
                ></i>
              </button>
            </div>
            <div className="d-flex mb-4 speaker-box">
              <div className="organiser-image-cont-2 rounded-circle me-3">
                <img
                  src={speakerData?.image_url}
                  style={{ width: "180px" }}
                  alt={`${speakerData.name} poster image`}
                  className="speaker-image2"
                />
              </div>
              <div className="d-flex flex-column speaker-about ">
                <p className="speaker-name2">{speakerData.name}</p>
                <p className="speaker-designation2">
                  {speakerData.designation}
                </p>
                <p className="speaker-organisation2">
                  {speakerData.organisation}
                </p>
                <div>
                  {speakerData.facebook_url && (
                    <a
                      className="me-2"
                      href={speakerData.facebook_url}
                      target={"_blank"}
                    >
                      <img src="/img/facebook03.svg" alt="" />
                    </a>
                  )}
                  {speakerData.twitter_url && (
                    <a
                      className="me-2"
                      href={speakerData.twitter_url}
                      target={"_blank"}
                    >
                      <img src="/img/twitter-red.svg" alt="" />
                    </a>
                  )}
                  {speakerData.linkedin_url && (
                    <a
                      className="me-2"
                      href={speakerData.linkedin_url}
                      target={"_blank"}
                    >
                      <img src="/img/linkedin03.svg" alt="" />
                    </a>
                  )}
                  {speakerData.website_url && (
                    <a
                      className="me-2"
                      href={speakerData.website_url}
                      target={"_blank"}
                    >
                      <img src="/img/Website03.svg" alt="" />
                    </a>
                  )}
                </div>
              </div>
            </div>

            {speakerData.about &&
              <p
                className="mb-0 desc"
                style={{ fontSize: "16px" }}
                dangerouslySetInnerHTML={{ __html: `${speakerData.about?.replace(/(?:\r\n|\r|\n)/g, '') || ''}` }}
              />
            }
            {speakerData.tags && speakerData.tags.length > 0 && (
              <div className="speaker-tags">
                <p className="my-3 tag-header">Tags</p>
                <div className="d-flex flex-wrap my-3">
                  {speakerData.tags.map((tag) => (
                    <div key={tag.id} className="me-2 mb-2 tag-container">
                      <span className="tag-name">#{tag.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </TrendySideViewModal>
      </>
    );
  };

  return (
    <div id="speakers">
      <div className="speaker_heading_description eventpage-fixed-padding">
        {eventData?.speaker_section_title ? (
          <div className="d-flex justify-content-sm-center justify-content-start align-items-center speaker_title_wrapper">
            <div className="event-heading">
              {eventData?.speaker_section_title}
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center speaker_title_wrapper">
            <div className="event-heading">Event</div>
            <div className="speaker-heading-wrapper d-flex align-items-center">
              <div className="speaker-heading">Speaker(s)</div>
              <img className="image_speaker" src={speaker} alt="Speaker" />
            </div>
          </div>
        )}

        {eventData?.speaker_section_description && (
          <div
            className={`d-flex justify-content-center mt-4 speaker_section_description`}
          >
            <ReadMore
              content={eventData.speaker_section_description}
              maxChar={"auto"}
              styles={{
                fontSize: "16px",
                fontWeight: "400",
                color: "rgba(20, 27, 37, 1)",
              }}
            />
          </div>
        )}
      </div>
      <div className="speakers_main">
        {speakers
          .sort((a, b) => a?.speaker_order - b?.speaker_order)
          .reduce(
            (rows, key, index) =>
              (index % 4 == 0
                ? rows.push([key])
                : rows[rows.length - 1].push(key)) && rows,
            []
          )
          .map((each, index) => (
            <div
              className={`web-view ${each.length === 2 || each.length === 3
                ? "justify-content-center"
                : ""
                } w-100`}
            >
              <div
                className={`d-flex image_container_wrapper justify-content-around ${each.length === 2
                  ? "w-50"
                  : each.length === 3
                    ? "w-75"
                    : "w-100"
                  }`}
              >
                {each?.map((data, index) => (
                  <ImageContainer
                    each={data}
                    index={index}
                    length={each.length}
                  />
                ))}
              </div>
            </div>
          ))}
        {speakers
          .sort((a, b) => a?.speaker_order - b?.speaker_order)
          .reduce(
            (rows, key, index) =>
              (index % 2 == 0
                ? rows.push([key])
                : rows[rows.length - 1].push(key)) && rows,
            []
          )
          .map((each, pos) => (
            <div className="row mob_view me-0">
              {each?.map((data, index) => (
                <MobileImageContainer
                  data={data}
                  index={index}
                  length={each.length}
                  pos={pos}
                />
              ))}
            </div>
          ))}
      </div>

      <style jsx global>
        {`
          #speakers {
            position: relative;
          }
          .speaker_heading_description {
            background-color: #fff;
            
          }
          .tag-header{
          font-family: "Prompt";
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #141B25;
        }
        .tag-container{
          background-color: #FFF2F1;
          height: 28px;
          padding: 0px 12px 0px 12px;
          border-radius: 4px;
        }
        .tag-name{
          font-family: "Hind";
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #212529;
        }
          .speaker-box {
            gap: 20px;
          }
          .desc {
              font-family: "Prompt";
              font-weight: 400;
              color: #212529;
              font-size: 16px;
              overflow-wrap: break-word;
            }

          .speaker-about {
            gap: 10px;
          }
          .card-box2 {
            height: 456px;
            overflow: scroll;
            width: 100%;

          }
          .card-box {
            width: 100% !important;
          }

          .speaker-name2 {
            font-family: "Prompt";
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #fb5850;
          }
          .speaker-designation2 {
            font-family: "Prompt";
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            color: #212529;
          }
          .twitter{
            height:24px;
          }
          .speaker-organisation2 {
            font-family: "Prompt";
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            color: #212529;
          }
          .speakers_main {
            //background-color: #260e20;
            background-color: #260E20;
            padding-left: 6rem;
            padding-right: 6rem;
          }
          .event-heading {
            font-family: "Prompt";
            font-style: normal;
            font-weight: 700;
            font-size: 64px;
            line-height: 130%;
            text-align: center;
            color: #572148;
          }
          .speaker-heading-wrapper {
            margin-left: 40px;
          }
          .speaker-heading {
            font-family: "Caveat Brush";
            font-style: normal;
            font-weight: 400;
            font-size: 64px;
            line-height: 130%;
            color: #fb5850;
            margin-right: 10px;
          }
          .speaker_title_wrapper {
            margin-bottom: ${!eventData?.speaker_section_description
            ? "100px"
            : "40px"};
          }
          .speaker_section_description {
            margin-bottom: 100px;
          }
          .card-box2 {
            width: 100%;
          }
          .people_wrapper {
            margin-top: 1.5rem;
          }
          .people {
            width: 265px;
            height: 300px;
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
          }
          .gray {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            -webkit-filter: grayscale(100%);
            z-index: 99;
            width: 265px;
            height: 300px;
            backdrop-filter: blur(0px);
          }
          .shadow-card {
            background: linear-gradient(
              360deg,
              rgb(0 0 0 / 72%) 0%,
              rgb(0 0 0 / 0%) 100%
            );
            position: absolute;
            top: 235px;
            bottom: 0;
            left: 0;
            right: 0;
          }
          .bio_box {
            background: none;
            color: #ffffff;
            width: 265px;
            padding-left: 30px;
            border-right: 4px solid #fb5850;
            z-index: 99;
            position: relative;
            right: 0;
            top: 265px;
            left: -20px;
            bottom: 0;
          }
          .speaker-name {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.04em;
            margin-left: 5px;
          }
          .speaker-designation {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.04em;
            margin-left: 5px;
          }
          .people:hover > .gray {
            display: none;
          }
          .people:hover > .bio_box {
            background: #fb5850;
            z-index: 20;
          }
          .web-view {
            display: flex;
            margin-top: -30px;
          }
          .image_container_wrapper {
            margin-top: -215px;
            margin-bottom: 200px;
          }
          .website {
            width: 22px;
          }
          @media only screen and (min-width: 1145px) {
            .wrapper {
              display: none;
            }
            .card-box2 {
              height: 456px;
              overflow: scroll;
              width: 100%;
            }
            .mob_view {
              display: none;
            }
          }
          @media only screen and (min-width: 900px) {
            .card-box2 {
              height: 456px;
              overflow: scroll;
              width: 100%;
            }
          }
          @media only screen and (max-width: 1343px) {
            .people {
              height: 220px;
              width: 220px;
            }
            .card-box2 {
              height: 456px;
              overflow: scroll;
              width: 100%;
            }
            .gray {
              height: 220px;
              width: 220px;
            }
            .bio_box {
              width: 220px;
              // height: 90px;
              padding-left: 30px;
              border-right: 3px solid #fb5850;
              z-index: 99;
              position: relative;
              right: 0;
              top: 185px;
              left: -20px;
              bottom: 0;
            }
            .shadow-card {
              position: absolute;
              top: 170px;
              bottom: 0;
              left: 0;
              right: 0;
            }
          }
          @media only screen and (max-width: 1145px) {
            .speakers_main {
              padding-left: 0rem;
              padding-right: 0rem;
            }
            .card-box2 {
              height: 456px;
              overflow: scroll;
              width: 100%;
            }
            .web-view {
              display: none;
            }
            .wrapper {
              margin-top: -130px;
              margin-bottom: 100px;
            }
            .wrapper_mobile {
              margin-top: -40px;
              margin-bottom: 110px;
            }
            .facebook_wrapper,
            .twitter_wrapper {
              margin-left: 10px;
            }
          }
          @media only screen and (max-width: 1145px) {
              .shadow-card {
                  webkit-filter: grayscale(100%) !important;
                  backdrop-filter: blur(0px) !important;
                  filter: grayscale(100%) !important;
                  background: 0;
              }
          }
          @media only screen and (max-width: 769px) {
            .speaker-box {
              flex-direction: column;
              align-items: start !important;
            }
            .card-box {
              overflow: scroll;
              height: 630px !important;
              width: 300px !important;
            }
            .speakers_main {
              padding-left: 5rem;
              padding-right: 5rem;
            }
            .speaker_heading_description {
              padding-left: 3rem;
              padding-right: 3rem;
            }
            .speaker_title_wrapper {
              margin-bottom: ${!eventData?.speaker_section_description
            ? "110px"
            : "30px"};
            }
            .speaker_section_description {
              margin-bottom: ${speakers.length === 1 ? "-25px" : "70px"};
            }
            .desc {
              font-family: "Prompt";
              font-size: 16px;
              font-weight: 400;
              color: #212529;
              overflow-wrap: break-word;
            }
          }
          @media only screen and (max-width: 647px) {
            .speaker-box {
              flex-direction: column;
              align-items: start !important;
            }
            .card-box {
              overflow: scroll;
              height: 630px !important;
              width: 320px !important;
            }
            .people_wrapper {
              margin-top: 145px;
            }
            .people {
              height: 180px;
              width: 180px;
            }
            .gray {
              height: 180px;
              width: 180px;
            }
            .bio_box {
              width: 180px;
              padding-left: 30px;
              border-right: 3px solid #fb5850;
              z-index: 99;
              position: relative;
              right: 0;
              top: 150px;
              left: -20px;
              bottom: 0;
            }
            .shadow-card {
              position: absolute;
              top: 135px;
              bottom: 0;
              left: 0;
              right: 0;
            }
            .speaker-name {
              font-size: 14px;
              line-height: 20px;
            }
            .speaker-designation {
              font-size: 10px;
              line-height: 14px;
            }
            .desc {
              font-family: "Prompt";
              font-size: 14px;
              font-weight: 400;
              overflow-wrap: break-word;
              color: #212529;
            }
          }
          @media (max-width: 600px) {
            .card-box {
              overflow: scroll;
              height: 630px !important;
              width: 320px !important;
            }
            .speaker-box {
              flex-direction: column;
              align-items: start !important;
            }
            .speaker-about {
              font-size: 14px !important;
              line-height: 20px !important;
            }
            organiser-image-cont-2 {
              width: 120px !important;
              border-radius:4px !important;
            }
            organiser-image-cont-2 img {
              border-radius:4px !important;
            }
            .speaker-image2 {
              width: 120px !important;
              border-radius: 4px;
            }
            .speaker-name2 {
              font-family: "Prompt";
              font-weight: 500;
              font-size: 20px;
              line-height: 30px;
              color: #fb5850;
            }
            .speaker-designation2 {
              font-family: "Prompt";
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              color: #212529;
            }
            .speaker-organisation2 {
              font-family: "Prompt";
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              color: #212529;
            }
            .desc {
              font-family: "Prompt";
              font-weight: 400;
              overflow-wrap: break-word;
              color: #212529;
            }
            .speaker_section_description {
              margin-bottom:35px;
            }
          }
          @media only screen and (max-width: 575px) {
            .event-heading {
              font-size: 18px !important;
              font-weight: 600;
            }
            .speaker-name{
              font-size: 11.11px !important;
            }
             .speaker-designation {
              font-size: 8.8px;
              line-height: 14px;
            }
            .eventpage-fixed-padding{
              padding: 2rem 1.5rem 1rem 1.5rem !important;
            }
            .speaker_title_wrapper {
                margin-bottom: 15px;
              }
              .people:hover > .gray {
                  display: none;
          }
              .people:hover > .bio_box {
                background: #fb5850;
                z-index: 20;
              }
              .speaker-designation2 {
                font-family: "Prompt";
                font-weight: 300;
                font-size: 14px;
              }
              .speaker-about {
                gap: 5px;
              }
            }
          @media only screen and (max-width: 480px) {
            .card-box {
              overflow: scroll;
              height: 630px !important;
              width: 320px !important;
            }
            .speakers_main {
              padding-left: 2rem;
              padding-right: 2rem;
            }
            .speaker_heading_description {
              padding-left: 2rem;
              padding-right: 2rem;
            }
            .speaker-heading {
              font-size: 40px;
              margin-right: 0px;
            }
            .speaker-heading-wrapper {
              margin-left: 10px;
            }
            .image_speaker {
              height: 85px;
              width: 80px;
            }
            .people {
              height: 180px;
              width: 160px;
            }
            .gray {
              height: 180px;
              width: 160px;
            }
            .shadow-card {
              position: absolute;
              top: 145px;
              bottom: 0;
              left: 0;
              right: 0;
            }
            .bio_box {
              position: relative;
              top: 152px;
              bottom: 0;
              left: 0;
              right: 0;
              width: 135px;
              padding-left: 5px;
              border-right: 3px solid #fb5850;
            }
            .speaker-name {
              font-size: 14px;
              line-height: 20px;
            }
            .speaker-designation {
              font-size: 8.8px;
              line-height: 14px;
            }
            .linkedIn,
            .facebook,
            .twitter,
            .website {
              width: 15px;
            }
            .gray {
              display: none;
            }
            .desc {
              font-family: "Prompt";
              font-size: 14px;
              font-weight: 400;
              overflow-wrap: break-word;
              color: #212529;
            }
            .speakers_main{
              margin-top: clamp(50px, 5vw, 140px); 
              margin-bottom: clamp(10px, 5vw, 40px);
            }
            
          }
          @media only screen and (max-width: 376px) {
            .card-box {
              overflow: scroll;
              height: 630px !important;
              width: 320px !important;
            }
            .people {
              height: 160px;
              width: 140px;
            }
            .bio_box {
              position: relative;
              top: 132px;
              bottom: 0;
              left: 0;
              right: 0;
              width: 135px;
              padding-left: 5px;
              border-right: 3px solid #fb5850;
            }
            .shadow-card {
              position: absolute;
              top: 130px;
              bottom: 0;
              left: 0;
              right: 0;
            }
            .desc {
              font-family: "Prompt";
              font-size: 14px;
              font-weight: 400;
              overflow-wrap: break-word;
              color: #212529;
            }
          }
          @media only screen and (max-width: 321px) {
            .card-box {
              overflow: scroll;
              height: 630px !important;
              width: 300px !important;
            }
            .speakers_main {
              padding-left: 1rem;
              padding-right: 1rem;
            }
            .people {
              height: 130px;
              width: 130px;
            }
            .bio_box {
              position: relative;
              top: 103px;
              bottom: 0;
              left: 0;
              right: 0;
              width: 120px;
            }
            .shadow-card {
              position: absolute;
              top: 100px;
              bottom: 0;
              left: 0;
              right: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default EventSpeakers;
