import React from 'react'

const ComingSoon = () => {
    return (
        <>
        <div className="coming-soon-btn d-flex align-items-center justify-content-center py-2 px-4">
            <span className="">Coming Soon</span>
        </div>
        <style jsx>{`
            .coming-soon-btn{
                background: #fff;
                box-shadow: 0px 2.22081px 8.32803px rgba(0, 0, 0, 0.15);
                border-radius: 5px;
                // border-radius: 20px 20px 50px 40px;
                // clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 100%);
            }
            .coming-soon-btn span{
                font-family: Prompt;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 130%;
                text-align: center;
                color: rgba(251, 88, 80, 1);
            }
            @media only screen and (max-width: 576px){
                .coming-soon-btn{
                    height:40px;
                }
                .coming-soon-btn span{
                    font-size: 14px !important;
                    font-weight: 600;
                }
            }
            `}
        </style>
        </>
    )
}

export default ComingSoon
